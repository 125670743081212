import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';


import {CoreKnowledgeService} from '../core-knowledge.service';
import { Page } from '@appModels/core-knowledge-base/pages/page';


@Injectable({providedIn: 'root'})
export class KnowledgeBasePageService extends CoreKnowledgeService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'knowledgebasepages');
  }

  /** GET knowledgeBasePage from the server */
  getKnowledgeBasePages(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + `/tenant/` + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(knowledgeBasePage => this.log(`fetched knowledgeBasePage`)),
      catchError(this.handleError('getKnowledgeBasePage', []))
    );
  }

  /** GET knowledgeBasePage from the server */
  searchKnowledgeBasePages(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + `/tenant/` + localStorage.getItem('tenant') + `/search`, {
      params,
      headers: this.headers
    }).pipe(
      tap(knowledgeBasePage => this.log(`fetched knowledgeBasePage`)),
      catchError(this.handleError('getKnowledgeBasePage', []))
    );
  }

  /** GET knowledgeBasePage by id. Return `undefined` when id not found */
  getKnowledgeBasePageNo404<Data>(id: number): Observable<Page> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Page[]>(url).pipe(
      map(knowledgeBasePage => knowledgeBasePage[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} knowledgeBasePage id=${id}`);
      }),
      catchError(this.handleError<Page>(`getKnowledgeBasePage id=${id}`))
    );
  }

  /** GET knowledgeBasePage by id. Will 404 if id not found */
  getKnowledgeBasePage(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBasePage id=${id}`)),
      catchError(this.handleError<any>(`getKnowledgeBasePage id=${id}`))
    );
  }

  getPublicKnowledgeBasePage(id: number): Observable<any> {
    const url = `${this.base_url}/public/page/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched publicKnowledgeBasePage id=${id}`)),
      catchError(this.handleError<any>(`getPublicKnowledgeBasePage id=${id}`))
    );
  }

  /** GET knowledgeBasePage by version id. Will 404 if id not found */
  getKnowledgeBasePageVersions(tenantId: number, id: number): Observable<any> {
    const url = `${this.base_url}/${tenantId}/${id}/versions`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBasePage id=${id}`)),
      catchError(this.handleError<any>(`getKnowledgeBasePage id=${id}`))
    );
  }

  /** GET knowledgeBasePage by tenant id. Will 404 if id not found */
  getKnowledgeBasePageBySpace(spaceId): Observable<any> {
    const url = `${this.base_url}/space/${spaceId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBasePage id=`)),
      catchError(this.handleError<any>(`getKnowledgeBasePage id=`))
    );
  }

   /** GET knowledgeBasePageFile by id. Will 404 if id not found */
   getKnowledgeBasePageFiles(fileId: number): Observable<Page> {
    const url = `${this.base_url}/file/${fileId}/`;
    return this.http.get<Page>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBasePage id=${fileId}`)),
      catchError(this.handleError<Page>(`getKnowledgeBasePage id=${fileId}`))
    );
  }

  /* GET knowledgeBasePage whose name contains search term */
  searchKnowledgeBasePage(term: string): Observable<Page[]> {
    if (!term.trim()) {
      // if not search term, return empty knowledgeBasePage array.
      return of([]);
    }
    return this.http.get<Page[]>(`api/knowledgeBasePage/?name=${term}`).pipe(
      tap(_ => this.log(`found knowledgeBasePage matching "${term}"`)),
      catchError(this.handleError<Page[]>('searchKnowledgeBasePage', []))
    );
  }

  //////// Save methods //////////
  /** POST: add a new knowledgeBasePage to the server */
  addKnowledgeBasePage(knowledgeBasePage): Observable<any> {
    this.setHeader(true);
    return this.http.post<any>(this.base_url, knowledgeBasePage, {headers: this.headers}).pipe(
      tap((tm: any) => this.log(`added knowledgeBasePage w/ id=${tm.id}`)),
      catchError(this.handleError<any>('addKnowledgeBasePage'))
    );
  }

  /** DELETE: delete the knowledgeBasePage from the server */
  deleteKnowledgeBasePage(knowledgeBasePage: Page | number): Observable<Page> {
    const id = typeof knowledgeBasePage === 'number' ? knowledgeBasePage : knowledgeBasePage.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Page>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted knowledgeBasePage id=${id}`)),
      catchError(this.handleError<Page>('deleteKnowledgeBasePage'))
    );
  }

  /** PUT: update the knowledgeBasePage on the server */
  updateKnowledgeBasePage(knowledgeBasePage, id): Observable<any> {
    this.setHeader(true);
    return this.http.post(`${this.base_url}/${id}`, knowledgeBasePage, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated knowledgeBasePage id=${knowledgeBasePage.id}`)),
      catchError(this.handleError<any>('updateKnowledgeBasePage'))
    );
  }

   /** Patch: update the KnowledgeBasePage on the server */
   editKnowledgeBasePage(data): Observable<any> {
    return this.http.patch(`${this.base_url}/page/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit KnowledgeBasePage id=${data.id}`)),
      catchError(this.handleError<any>('editKnowledgeBasePage'))
    );
  }

  downloadAttachment(attachmentId): Observable<any> {
    return this.http.get<Blob>(`${this.base_url.slice(0, -1)}/file/${attachmentId}`,
      {headers: this.headers, responseType: 'blob' as 'json'}).pipe(
      tap(_ => this.log(`get knowledgeBasePageAttachment`)),
      catchError(this.handleError<any>('get knowledgeBasePageAttachment'))
    );
  }

  loadFile(id: number): Observable<any> {
    const url = `${this.base_url.slice(0, -1)}/file/${id}`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`get knowledgeBasePageAttachment`)),
      catchError(this.handleError<any>('get knowledgeBasePageAttachment'))
   );
  }

  downloadPublicAttachment(attachmentId: number): Observable<any> {
    return this.http.get<Blob>(`${this.base_url}/public/file/${attachmentId}`,
      {headers: this.headers, responseType: 'blob' as 'json'}).pipe(
      tap(_ => this.log(`get publicKnowledgeBasePageAttachment`)),
      catchError(this.handleError<any>('get publicKnowledgeBasePageAttachment'))
    );
  }


  archivePage(pageId: number): Observable<any> {
    return this.http.put<any>(`${this.base_url}/archive/${pageId}`,null,
      {headers: this.headers}).pipe(
      tap(_ => this.log(`put publicKnowledgeBasePageArchive`)),
      catchError(this.handleError<any>('put publicKnowledgeBasePageArchive'))
    );
  }

}
